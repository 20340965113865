export const FIELD_TYPES = {
  TEXT: "text",
  IMAGE: "image",
  LIST: "list",
  COLOR: "color",
} as const;

export const FIELD_NAME_SUFFIX = {
  TEXT: "text",
  URL: "url",
  IMAGE: "image",
  COLOR: "color",
} as const;

export const FIELD_INPUT_TYPES = FIELD_NAME_SUFFIX;

export const PREFIXES = {
  STATIC_KEY: "STATIC_KEY",
  DYNAMIC_KEY: "DYNAMIC_KEY",
  STYLE: "STYLE",
} as const;

export const DYNAMIC_KEYS = {
  COMPANY_LOGO: "c_logo",
  COMPANY_NAME: "c_name",
} as const;

export const CREATIVE_TEMPLATE_TYPES = {
  PERSONALIZED: "personalized",
  STANDARD: "standard",
} as const;

export const TEMPLATE_LIST_KEYS = {
  default: () => ["templates"],
  withId: ({ id }: any) => [...TEMPLATE_LIST_KEYS.default(), id],
  withType: ({ type = "all" }: any) => [...TEMPLATE_LIST_KEYS.default(), type],
};

export const TEMPLATE_KEYS = {
  default: () => ["template"],
  withId: ({ id }: any) => [...TEMPLATE_KEYS.default(), id],
};

export const TEMPLATE_FIELDS = {
  default: () => ["template", "fields"],
  withId: ({ id }: any) => [...TEMPLATE_KEYS.default(), id],
  withMappingId: ({ templateMappingId }: any) => [
    ...TEMPLATE_FIELDS.default(),
    "mapping",
    templateMappingId,
  ],
};
export const PERSONALIZED_VARIABLES = {
  COMPANY_NAME: "{{c_name}}",
  INDUSTRY: "{{c_industry}}",
} as const;

export const PERSONALIZED_VARIABLES_MANUAL_PERSONALIZED = {
  COMPANY_NAME: "{{company_name}}",
  INDUSTRY: "{{industry}}",
} as const;

export const REGEX_PERSONALIZED_VARIABLES_MANUAL_PERSONALIZED = new RegExp(
  /{{(company_name|industry)}}/g
);
