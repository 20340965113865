import Image from "@/components/Image";
import useImageLoaded from "@/hooks/useImageLoaded";
import { useGetBwCreativeDetails } from "@/modules/Asset/Creative/asset.hook";
import BrokenImageOutlinedIcon from "@mui/icons-material/BrokenImageOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import { Skeleton } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
const CustomListWithImageButtonForAutocomplete = React.forwardRef(
  function CustomListWithImageButtonForAutocomplete(props: any, ref) {
    const {
      children: options,
      customProps: { handleClickOpenImage, list },
      ...other
    } = props;

    return (
      <List {...other} ref={ref}>
        {options.map((option: Record<string, any>, index: number) => {
          const {
            key,
            props: { ...optionProp },
            ...rest
          } = option;
          const optionIndex = optionProp["data-option-index"];
          const templateCreative = list[optionIndex]?.templateCreative;
          const {
            loadedStatusForThumbnail,
            loadedStatusForPreviewUrl,
            image,
            previewUrl,
            urlsLoading,
          } = loadBeeswaxImage(list[optionIndex]?.adId, templateCreative);

          return (
            <ListItem key={index} {...optionProp}>
              <ListItemText primary={key} />
              <IconButton
                sx={{
                  fontSize: "22px",
                  "&:hover": {
                    backgroundColor: "inherit",
                    opacity: "0.7",
                  },
                }}
                onClick={(e) => {
                  handleClickOpenImage(e, optionIndex, {
                    imageUrl: previewUrl,
                    loadedStatus: templateCreative
                      ? "loaded"
                      : loadedStatusForPreviewUrl,
                    urlsLoading,
                    dimentions: list[optionIndex]?.dimentions,
                    templateCreative,
                  });
                }}
                disabled={urlsLoading || loadedStatusForThumbnail === "error"}
              >
                {templateCreative ? (
                  <ImageOutlinedIcon />
                ) : !urlsLoading && loadedStatusForThumbnail === "error" ? (
                  <BrokenImageOutlinedIcon />
                ) : urlsLoading || loadedStatusForThumbnail === "loading" ? (
                  <Skeleton variant="rounded" width={22} height={22} />
                ) : loadedStatusForThumbnail === "loaded" ? (
                  <Image img={image} alt="" maxWidth="22px" maxHeight="22px" />
                ) : null}
              </IconButton>
            </ListItem>
          );
        })}
      </List>
    );
  }
);

export default CustomListWithImageButtonForAutocomplete;

function loadBeeswaxImage(
  assetId: string,
  templateCreative: Record<string, any> | undefined | null
) {
  if (templateCreative)
    return {
      loadedStatusForThumbnail: "loaded",
      loadedStatusForPreviewUrl: "loaded",
      image: null,
      previewUrl: null,
      urlsLoading: false,
    };
  const { data: creativeDetails, isLoading: creativeDetailsLoading } =
    useGetBwCreativeDetails({
      id: assetId,
    });

  const loadedStatusForThumbnail = useImageLoaded({
    src: creativeDetails?.thumbnailUrl,
    srcLoading: creativeDetailsLoading,
  });
  const loadedStatusForPreviewUrl = useImageLoaded({
    src: creativeDetails?.previewUrl,
    srcLoading: creativeDetailsLoading,
  });

  return {
    loadedStatusForThumbnail,
    loadedStatusForPreviewUrl,
    image: creativeDetails?.thumbnailUrl,
    previewUrl: creativeDetails?.previewUrl,
    urlsLoading: creativeDetailsLoading,
  };
}
