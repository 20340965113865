import {
  getCreativeTemplateFieldsByTemplateMappingId,
  getTemplate,
  getTemplateList,
} from "@/apis/v1/template";
import convertRGBACSSToRGBAObject from "@/utils/convertRGBACSSToRGBAObject";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import {
  TEMPLATE_FIELDS,
  TEMPLATE_KEYS,
  TEMPLATE_LIST_KEYS,
} from "./template.constant";
import {
  CampaignType,
  Field,
  Fields,
  SearchApiResponse,
  TemplateApiResponse,
  TemplateDataValueApiResponse,
  TemplateFormFields,
  TemplateListKeys,
} from "./template.interface";
import {
  aiFormFieldValidationSchema,
  fieldsValidationSchema,
} from "./templateFields.validation";
import { useState } from "react";
import { getPhotos } from "@/apis/v1/pexels";

export function useEditForm({
  templateFields,
  defaultSelectedVariantId,
  advertiserId,
}: {
  templateFields: TemplateFormFields;
  defaultSelectedVariantId: number;
  advertiserId: number | undefined;
}) {
  const editMode = "multi";

  const defaultValidationSchema = fieldsValidationSchema({
    ...templateFields.fields,
    global: templateFields.allFields,
  });
  const [validationSchema, setValidationSchema] = useState<any>(
    defaultValidationSchema
  );
  const curriedValidationSchemaUpdater =
    validationSchemaUpdater(setValidationSchema);
  const rhForm = useForm<any>({
    defaultValues: {
      editMode,
      advertiserId: advertiserId ?? null,
      creativeName: "",
      selectedVariantId: defaultSelectedVariantId ?? null,
      global: templateFields.allFormFields,
      ...templateFields.formFields,
    },
    resolver: yupResolver(validationSchema),
  });

  return {
    rhForm,
    curriedValidationSchemaUpdater,
  };
}

function validationSchemaUpdater(
  setValidationSchema: React.Dispatch<React.SetStateAction<any>>
) {
  return (fieldsByVariant: Record<string | number, Field>) =>
    setValidationSchema(fieldsValidationSchema(fieldsByVariant));
}

export function useAiForm({
  campaignType,
  advertiserId,
}: {
  campaignType: CampaignType;
  advertiserId: number | undefined;
}) {
  const defaultValues: Record<string, string | number | null | string[]> = {
    campaignType,
    campaignObjective: null,
    productServiceDescription: "",
    valueProposition: "",
    toneStyle: null,
    keywords: [],
    cta_text: null,
    advertiserId: advertiserId ?? null,
    imageDescriptions: null,
  };
  if (campaignType === "personalized") {
    defaultValues["audienceId"] = null;
    defaultValues["personalizationVariables"] = [];
  }
  const aiForm = useForm<any>({
    defaultValues,
    resolver: yupResolver(aiFormFieldValidationSchema(campaignType)),
  });

  return {
    aiForm,
  };
}

export function useEditTemporaryForm({ fields }: { fields: Fields }) {
  const fieldDefaultValues = Object.fromEntries(
    Object.entries(fields.dynamic).map(([key, value]) => [value.name, null])
  );

  const rhForm = useForm<any>({
    defaultValues: {
      ...fieldDefaultValues,
    },
  });

  return {
    rhTempForm: rhForm,
  };
}

export function useTemplate(args: {
  id: number | undefined;
  queryParams?: {
    name?: string;
    type?: string;
  };
  options?: Record<any, any>;
}) {
  const { id, queryParams = {}, options = {} } = args;

  return useQuery<TemplateApiResponse>(
    TEMPLATE_KEYS.withId(id),
    (context) => getTemplate(id, context),
    {
      meta: {
        queryParams: { ...queryParams },
      },
      enabled: !!id,
      ...options,
    }
  );
}

export function useTemplateList(
  args?: {
    queryParams?: {
      type?: string;
    };
    options?: Record<any, any>;
  },
  key: TemplateListKeys = "withType"
) {
  const { queryParams = {}, options = {} } = args ?? {};
  return useQuery<TemplateApiResponse[]>(
    TEMPLATE_LIST_KEYS[key](queryParams),
    getTemplateList,
    {
      meta: {
        queryParams: { ...queryParams },
      },
      ...options,
    }
  );
}
export function usePhotos(args: {
  queryParams: {
    imageQuery: string;
  };
  options?: Record<any, any>;
}) {
  const { queryParams, options = {} } = args;
  return useQuery<SearchApiResponse>(
    ["photos", queryParams.imageQuery],
    getPhotos,
    {
      meta: {
        queryParams: { ...queryParams },
      },
      ...options,
    }
  );
}

export function useTemplateFieldsByTemplateMappingId(args: {
  templateMappingId: number;
  queryParams?: {
    name?: string;
    type?: string;
  };
  options?: Record<any, any>;
}) {
  const { templateMappingId, queryParams = {}, options = {} } = args;

  return useQuery<TemplateDataValueApiResponse[]>(
    TEMPLATE_FIELDS.withMappingId({ templateMappingId }),
    (context) =>
      getCreativeTemplateFieldsByTemplateMappingId(templateMappingId, context),
    {
      meta: {
        queryParams: { ...queryParams },
      },
      ...options,
    }
  );
}

export function useTemplateFieldValueMapper() {
  const templateFieldsToValues = (fields: Field) =>
    Object.fromEntries(
      Object.entries(fields).map(([key, value]) => [
        value.name,
        value.type === "color"
          ? convertRGBACSSToRGBAObject(value.defaultValue as string)
          : value.defaultValue,
      ])
    );
  return { templateFieldsToValues };
}
