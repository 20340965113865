import { insertColor } from "../helper";
import { ComponentProps, MyStyles } from "../template.interface";

export default function TemplateBox(props: ComponentProps) {
  const {
    id = "",
    children = null,
    styles = {},
    componentProps = {},
    values = {},
    fields = { static: {}, dynamic: {} },
    isEdit,
  } = props;
  return (
    <div
      style={{ ...insertColor(styles as MyStyles, values, fields, isEdit) }}
      {...componentProps}
    >
      {children}
    </div>
  );
}
