import { useEffect, useState } from "react";

interface CropData {
  x: number;
  y: number;
  width: number;
  height: number;
}

const scaleImage = (file: string, scale: number): Promise<string> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = file;
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      canvas.width = img.width * scale;
      canvas.height = img.height * scale;

      ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);

      const dataURL = canvas.toDataURL("image/webp");
      resolve(dataURL);
    };
  });
};

const resize = async (
  file: string,
  cropData: CropData,
  name: string,
  scaleFactor: number
): Promise<File | null> => {
  try {
    const { x, y, width, height } = cropData;
    const scaledImage = await scaleImage(file, scaleFactor);
    const img = new Image();
    img.src = scaledImage;
    await img.decode();

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) return null;

    canvas.width = width;
    canvas.height = height;

    ctx.drawImage(
      img,
      x * scaleFactor,
      y * scaleFactor,
      width * scaleFactor,
      height * scaleFactor,
      0,
      0,
      width,
      height
    );

    const resizedImgPromise = new Promise<File | null>((resolve) => {
      canvas.toBlob((blob) => {
        if (blob) {
          const resizedImgFile = new File([blob], `${name}.webp`, {
            type: "image/webp",
          });
          resolve(resizedImgFile);
        } else {
          resolve(null);
        }
      }, "image/webp");
    });

    const resizedImg = await resizedImgPromise;

    return resizedImg;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const readImage = (file: File) => {
  const [imageData, setImageData] = useState<string | ArrayBuffer | null>(null);

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target?.result) setImageData(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  }, [file]);

  return imageData;
};

const useImageProcessor = () => {
  return { resize, readImage };
};

export default useImageProcessor;
