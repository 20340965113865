import { ReactComponent as DisplayLogo } from "@/assets/dashboard/common/display.svg";
import { ReactComponent as EditIcon } from "@/assets/dashboard/common/edit.svg";
import { ReactComponent as LinkedinLogo } from "@/assets/dashboard/common/linkedin.svg";
import { ReactComponent as FacebookLogo } from "@/assets/dashboard/common/meta-min.svg";
import ControlledAutoComplete from "@/components/Form/InputFields/ControlledAutoComplete";
import PermissionGuard from "@/components/Guard/PermissionGuard";
import DeleteIcon from "@/components/Icon/DeleteIcon";
import Image from "@/components/Image";
import { StyledTableCell } from "@/components/Table/CustomTable";
import { MODULES } from "@/constants";
import { useDialogDispatch } from "@/context/DialogContext";
import { useSnackbarDispatch } from "@/context/SnackbarContext";
import { messages } from "@/messages";
import routes from "@/routes";
import parseJSON from "@/utils/parseJSON";
import { Badge, IconButton, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { useQueryClient } from "@tanstack/react-query";
import { capitalize } from "lodash";
import * as React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate } from "react-router-dom";
import DisplayTemplate from "../../Template/DisplayTemplate";
import { convertColorFields } from "../../Template/helper";
import { useTemplateFieldsByTemplateMappingId } from "../../Template/template.hook";
import { TemplateVariant } from "../../Template/template.interface";
import {
  CreativeType,
  internalLinkedinCreativeToInternalCreativeMapping,
} from "../CreateOrEditAsset/data";
import { AdFormatTypeUnion } from "../CreateOrEditAsset/types";
import { LIST_AD_CREATIVE, useDeleteAds, useThumbnail } from "../asset.hook";
import "./index.css";

export default function AssetTableRow({ row }: AssetTableRowProps) {
  const { setDialogState } = useDialogDispatch();
  const { handleImageSources } = useThumbnail();
  const imagesData = handleImageSources(row);

  const { setSnackbarState } = useSnackbarDispatch();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const assetInfo: any = parseJSON(row?.assetInfo);

  const templateValues = convertColorFields(
    row?.CreativeTemplateDataValueMapping?.CreativeTemplateDataValues?.[0]
      ?.fields
  );

  const template =
    row?.CreativeTemplateDataValueMapping?.CreativeTemplateVariant;

  const handleClickOpen = () => {
    setDialogState({
      open: true,
      title: row.name,
      children:
        row?.channel === "display" && template ? (
          <TemplateViewModal
            templateDataValues={
              row?.CreativeTemplateDataValueMapping
                ?.CreativeTemplateDataValues ?? []
            }
            template={template}
            templateMappingId={row?.CreativeTemplateDataValueMapping?.id}
          />
        ) : row?.channel === "display" ? (
          <iframe
            height={assetInfo?.dimentions?.height}
            width={assetInfo?.dimentions?.width}
            src={imagesData?.[0]?.previewUrl}
          ></iframe>
        ) : row?.assetUrl ? (
          <iframe height={"500px"} width={"100%"} src={row?.assetUrl}></iframe>
        ) : (
          <Carousel
            showThumbs={false}
            swipeable={true}
            showIndicators={false}
            showStatus={false}
            className="carousel"
          >
            {imagesData.map((item, i) => (
              <Image
                key={i}
                img={item.image}
                alt={"image"}
                loadedStatus={item.loadedStatus ?? "error"}
              />
            ))}
          </Carousel>
        ),
      styles: {
        ".MuiDialog-paper": {
          maxWidth: "max-content",
          minWidth:
            row?.channel === "display" && template
              ? { xs: "auto", md: "800px" }
              : "auto",
        },
      },
    });
  };

  const onEditClick = (e: any) => {
    e.stopPropagation();
    navigate(routes.ASSETS.CREATIVES.EDIT(row.id), {
      state: JSON.stringify(row),
    });
  };
  const { mutateAsync: deleteAd, isLoading: idDeleteLoading } = useDeleteAds();

  const onDeleteClick = (e: any) => {
    e.stopPropagation();
    setDialogState({
      open: true,
      title: "Confirmation",
      variant: "alert",
      buttonName: "ok",
      buttonLoading: idDeleteLoading,
      buttonFunction: deleteFunction,
      children: (
        <h4 style={{ margin: 0 }}>Are you sure you want to delete creative</h4>
      ),
    });
  };

  const deleteFunction = async () => {
    await deleteAd({
      id: row.id,
      type: row.channel,
    });
    queryClient.refetchQueries({
      queryKey: LIST_AD_CREATIVE(row.channel),
      exact: true,
    });
    setSnackbarState({
      open: true,
      message: messages.actionSuccessfully("record", "deleted"),
    });
    setDialogState({
      open: false,
      title: "",
    });
  };

  const channelLogo = () => {
    if (row.channel === "linkedin") {
      return <LinkedinLogo />;
    } else if (row.channel === "display") {
      return <DisplayLogo />;
    } else if (row.channel === "facebook") {
      return <FacebookLogo />;
    }
  };

  return (
    <>
      <StyledTableCell align="left">{row.name}</StyledTableCell>
      <StyledTableCell>{channelLogo()}</StyledTableCell>
      <StyledTableCell align="left">
        {row?.BeeswaxAdAssociations?.length ??
          row?.LinkedinAdAssociations?.length}
      </StyledTableCell>
      <StyledTableCell align="left">
        {assetInfo?.dimentions
          ? `${assetInfo?.dimentions?.width} x ${assetInfo?.dimentions?.height}`
          : "_"}
      </StyledTableCell>
      <StyledTableCell align="center">
        {assetInfo?.mimeType == "video" ? (
          <video width="150" height="55" controls>
            <source src={imagesData?.[0]?.image} type="video/mp4" />
          </video>
        ) : (
          <Badge
            badgeContent={imagesData.length ? imagesData.length - 1 : 0}
            color="primary"
          >
            <Box
              sx={{
                cursor: imagesData?.[0]?.imageIsLoaded ? "pointer" : "default",
                "& :hover": {
                  transform: imagesData?.[0]?.imageIsLoaded
                    ? "scale(1.1)"
                    : "none",
                },
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleClickOpen();
              }}
            >
              {row?.channel === "display" && template ? (
                <Box
                  sx={{
                    maxWidth: "40px",
                    maxHeight: "40px",
                    width: "40px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      transform: "scale(0.1)",
                      transformOrigin: "center",
                      "&:hover": {
                        transform: "scale(0.1)",
                        cursor: "pointer",
                      },
                    }}
                  >
                    {" "}
                    <PreviewTemplate
                      templateValues={templateValues ?? {}}
                      template={template}
                      isOverlay={true}
                    />
                  </Box>
                </Box>
              ) : (
                <Image
                  img={imagesData?.[0]?.image}
                  alt={row?.name}
                  loadedStatus={imagesData?.[0]?.loadedStatus ?? "error"}
                  maxWidth="120px"
                  maxHeight="50px"
                />
              )}
            </Box>
          </Badge>
        )}
      </StyledTableCell>
      <StyledTableCell align="left">
        {getCreativeType({ ...row, assetInfo }, row.channel)}
      </StyledTableCell>
      <StyledTableCell align="left">
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          {row.channel === "display" && (
            <PermissionGuard module={MODULES.CREATIVES} permissionType="update">
              <Tooltip title="Edit" onClick={onEditClick}>
                <IconButton>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </PermissionGuard>
          )}
          <PermissionGuard module={MODULES.CREATIVES} permissionType="delete">
            <Tooltip title="Delete" onClick={onDeleteClick}>
              <IconButton>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </PermissionGuard>
        </Box>
      </StyledTableCell>
    </>
  );
}

function getCreativeType(
  asset: any,
  type: "linkedin" | "display" | "facebook"
) {
  if (type === "display") {
    if (asset?.assetInfo && asset?.assetInfo?.type) {
      const assetType = asset?.assetInfo?.type;
      if (assetType.includes("application/")) {
        return `Bulk/${asset?.assetInfo?.mimeType}`;
      } else if (assetType.includes("text")) {
        return assetType?.split("/")?.[1];
      } else {
        return assetType?.split("/")?.[0];
      }
    } else if (asset?.creativeRuleKey && asset?.creativeContentTag) {
      return "JS Tag";
    }
  } else if (type === "facebook") {
    if (asset?.image_hash) {
      return CreativeType.IMAGE;
    } else if (asset?.video_id) {
      return CreativeType.VIDEO;
    }
  } else {
    return capitalize(
      internalLinkedinCreativeToInternalCreativeMapping[
        asset.type as AdFormatTypeUnion
      ]
    );
  }
}

interface AssetTableRowProps {
  row: any;
  selected: Array<any>;
  handleClickCheckBox: (event: React.MouseEvent<unknown>, name: string) => void;
}

const PreviewTemplate = (props: {
  template: TemplateVariant;
  templateValues: Record<string, any>;
  isOverlay?: boolean;
}) => {
  const { templateValues, template, isOverlay } = props;

  return (
    <DisplayTemplate
      isEdit={
        templateValues && Object.keys(templateValues)?.length > 0 ? true : false
      }
      isOverlay={isOverlay}
      values={templateValues}
      template={template.templateData}
      width={template.dimensions.width}
      height={template.dimensions.height}
    />
  );
};

export function TemplateViewModal({
  template,
  templateDataValues,
  templateMappingId,
}: {
  template: TemplateVariant;
  templateDataValues: {
    key: string;
    fields: Record<string, any>;
    templateMappingId: number;
  }[];
  templateMappingId: number;
}) {
  const [selectedTemplateValue, setSelectedTemplateValue] =
    React.useState<string>("BASE");
  const { data, isLoading } = useTemplateFieldsByTemplateMappingId({
    templateMappingId,
  });
  const allTemplateDataValues = data ?? templateDataValues ?? [];
  const base = allTemplateDataValues.find((item) => item.key === "BASE");
  const baseFields = base?.fields ? convertColorFields(base.fields) : null;
  if (!base || !baseFields) return <div>no data</div>;
  const fieldsExceptBase = allTemplateDataValues.filter(
    (item) => item.key !== "BASE"
  );
  const itemsObject = {
    [base.key as string]: {
      label: base.key,
      fields: baseFields,
    },
  };
  fieldsExceptBase.forEach((item) => {
    itemsObject[item.key as string] = {
      label: item.key as string,
      fields: item?.fields ? { ...baseFields, ...item.fields } : {},
    };
  });

  const itemList = Object.keys(itemsObject).map((key) => ({
    label: key,
    value: key,
  }));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        width: "100%",
        height: "100%",
        flex: 1,
      }}
    >
      <Box sx={{ width: "400px" }}>
        <ControlledAutoComplete
          value={selectedTemplateValue}
          setValue={setSelectedTemplateValue}
          itemList={itemList}
          loading={isLoading}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          pb: 2,
        }}
      >
        <PreviewTemplate
          template={template}
          templateValues={itemsObject[selectedTemplateValue].fields}
          isOverlay={false}
        />
      </Box>
    </Box>
  );
}
