import { getFieldsValues, insertColor } from "../helper";
import { ComponentProps, MyStyles } from "../template.interface";

export default function TemplateTextBox(props: ComponentProps) {
  const {
    id = "",
    styles = {},
    componentProps = {},
    content = {},
    wrapperProps = {},
    values = {},
    fields = { static: {}, dynamic: {} },
    isEdit,
  } = props;
  const { url, image, text } = getFieldsValues(content, values, fields, isEdit);

  return (
    <div
      style={{
        ...(wrapperProps?.styles
          ? insertColor(wrapperProps.styles as MyStyles, values, fields, isEdit)
          : {}),
      }}
    >
      <p
        style={{
          margin: 0,
          padding: 0,
          ...insertColor(styles as MyStyles, values, fields, isEdit),
        }}
        {...componentProps}
      >
        {text}
      </p>
    </div>
  );
}
