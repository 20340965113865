import { getFieldsValues, insertColor } from "../helper";
import { ComponentProps, MyStyles } from "../template.interface";

export default function TemplateList(props: ComponentProps) {
  const {
    id = "",
    styles = {},
    componentProps = {},
    content = {},
    wrapperProps = {},
    values = {},
    fields = { static: {}, dynamic: {} },
    isEdit,
  } = props;

  return (
    <ul
      style={{
        margin: 0,
        padding: 0,
        ...(wrapperProps?.styles
          ? insertColor(wrapperProps.styles as MyStyles, values, fields, isEdit)
          : {}),
      }}
    >
      {content?.text?.map((item: string, index: number) => {
        const { text } = getFieldsValues(
          { text: item },
          values,
          fields,
          isEdit
        );
        return (
          <li
            key={index}
            style={{
              ...insertColor(styles as MyStyles, values, fields, isEdit),
            }}
          >
            {text}
          </li>
        );
      })}
    </ul>
  );
}
