import { getFieldsValues, insertColor } from "../helper";
import { ComponentProps, MyStyles } from "../template.interface";

export default function TemplateCTA(props: ComponentProps) {
  const {
    id = "",
    styles = {},
    componentProps = {},
    content = {},
    wrapperProps = {},
    colors,
    values = {},
    fields = { static: {}, dynamic: {} },
    isEdit,
  } = props;

  const { text } = getFieldsValues(content, values, fields, isEdit);
  return (
    <div
      style={{
        ...(wrapperProps?.styles
          ? insertColor(wrapperProps.styles as MyStyles, values, fields, isEdit)
          : {}),
      }}
    >
      <button
        style={{
          background: "none",
          color: "inherit",
          border: "none",
          padding: 0,
          font: "inherit",
          cursor: "pointer",
          outline: "inherit",
          ...insertColor(styles as MyStyles, values, fields, isEdit),
        }}
        {...componentProps}
      >
        {text}
      </button>
    </div>
  );
}
