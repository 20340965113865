import { RgbaColor } from "react-colorful";

export default function convertRGBACSSToRGBAObject(color: string): RgbaColor {
  const [r, g, b, a] = color
    .replace("rgba(", "")
    .replace(")", "")
    .split(",")
    .map((v) => parseFloat(v));
  return { r, g, b, a };
}
