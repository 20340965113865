import convertRGBAObjectToRGBACSS from "@/utils/convertRGBAObjectToRGBACSS";
import {
  EditMode,
  Fields,
  MyStyles,
  SimilarNamedFieldsErrors,
} from "./template.interface";
import { PREFIXES } from "./template.constant";
import { RgbaColor } from "react-colorful";
import convertRGBACSSToRGBAObject from "@/utils/convertRGBACSSToRGBAObject";

export function insertColor(
  styles: MyStyles,
  values: Record<string, any>,
  fields: Fields,
  isEdit: boolean
) {
  if (!fields.static) return styles;
  const copy = { ...styles };
  for (const style in copy) {
    let styleValue = copy[style];
    if (typeof styleValue === "string") {
      const [prefix, fieldKey] = styleValue.trim().split(".");
      if (prefix === PREFIXES.STATIC_KEY && fieldKey) {
        if (isEdit) {
          styleValue = convertRGBAObjectToRGBACSS(
            values[fields.static[fieldKey].name]
          );
        } else {
          styleValue = fields.static[fieldKey].defaultValue as string;
        }
      }
    }
    copy[style] = styleValue;
  }
  return copy;
}

export function getFieldsValues(
  content: Record<string, any>,
  dataValues: Record<string, any>,
  fields: Fields,
  isEdit: boolean
) {
  const { static: staticFields, dynamic: dynamicFields } = fields;
  const staticKeys = Object.keys(staticFields);
  const dynamicKeys = Object.keys(dynamicFields);
  const values = {
    image: undefined,
    text: undefined,
    url: undefined,
  };

  if (content.image) {
    values.image = getValue({
      key: content.image,
      fields,
      staticKeys,
      dynamicKeys,
      dataValues,
      isEdit,
    });
  }

  if (content.text) {
    values.text = getValue({
      key: content.text,
      fields,
      staticKeys,
      dynamicKeys,
      dataValues,
      isEdit,
    });
  }

  if (content.url) {
    values.url = getValue({
      key: content.url,
      fields,
      staticKeys,
      dynamicKeys,
      dataValues,
      isEdit,
    });
  }

  return values;
}

function getValue({
  key,
  dataValues,
  fields,
  staticKeys,
  dynamicKeys,
  isEdit,
}: {
  key: string;
  dataValues: Record<string, any>;
  fields: Fields;
  staticKeys: string[];
  dynamicKeys: string[];
  isEdit: boolean;
}) {
  const [prefix, fieldKey] = key.split(".");
  if (key && fieldKey) {
    if (prefix === PREFIXES.STATIC_KEY) {
      if (staticKeys.includes(fieldKey)) {
        if (isEdit) {
          return dataValues[fields.static[fieldKey].name];
        }
        return fields.static[fieldKey].defaultValue;
      }
    }

    if (prefix === PREFIXES.DYNAMIC_KEY) {
      if (dynamicKeys.includes(fieldKey)) {
        if (isEdit) {
          return fields.dynamic[fieldKey].defaultValue;
        }
        return fields.dynamic[fieldKey].defaultValue;
      }
    }
  }
  return key;
}

export function getKeysFromFields(fields: Fields) {
  const staticFields = fields.static;
  return Object.keys(staticFields).reduce((acc, key) => {
    acc[key] = staticFields[key].name;
    return acc;
  }, {} as Record<string, string>);
}

export function convertColorFields(fields: any) {
  const values = { ...fields };
  Object.keys(values).forEach((key) => {
    if (key.endsWith("_color")) {
      values[key] = convertRGBACSSToRGBAObject(values[key]);
    }
  });
  return values;
}

export function getCustomErrorMessages(
  similarNamedFieldsErrors: SimilarNamedFieldsErrors,
  fieldName: string,
  editMode: EditMode
) {
  const fieldError = similarNamedFieldsErrors[fieldName];
  if (editMode === "multi" && fieldError) {
    const fieldErrorTypes = Object.keys(fieldError);
    const message = [];
    if (fieldError && fieldErrorTypes.length > 0) {
      for (const type of fieldErrorTypes) {
        message.push(
          fieldError[type].byVariant
            ? fieldError[type].messageWithVariantNames
            : fieldError[type].message
        );
      }
    }
    return message;
  }
  return null;
}
