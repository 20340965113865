import { InputFieldType } from "@/types";
import { Autocomplete, CircularProgress, OutlinedInput } from "@mui/material";
import * as React from "react";
import CustomFormControl from "../../FormControl/CustomFormControl";
import { CustomFormControlRequiredPropsForFields } from "../inputfields.interface";

export default function ControlledAutoComplete({
  value,
  setValue,
  itemList,
  label,
  placeholder,
  required,
  error,
  toolTip,
  disabled,
  loading,
  type = "primary",
}: ControlledAutoCompleteProps) {
  const [inputValue, setInputValue] = React.useState("");
  const values = React.useMemo(
    () => itemList.map((item: Record<string, string>) => item.value),
    [JSON.stringify(itemList)]
  );
  const valueKeyMapping = React.useMemo(() => {
    const obj: any = {};
    itemList.forEach((item: any) => (obj[item.value] = item.label));
    return obj;
  }, [values]);

  const secondaryStyles =
    type === "secondary"
      ? {
          backgroundColor: "background.default",
        }
      : {};
  return (
    <Autocomplete
      value={value}
      onChange={(event: any, newValue: string | null) => {
        if (newValue === null) return;
        setValue(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      id="controllable-states-demo"
      options={values}
      getOptionLabel={(option) => valueKeyMapping[option]}
      renderInput={(params) => {
        const { InputProps, inputProps, ...rest } = params;
        return (
          <CustomFormControl
            label={label}
            required={required}
            error={error}
            toolTip={toolTip}
            disabled={disabled}
          >
            <OutlinedInput
              {...rest}
              {...InputProps}
              placeholder={placeholder}
              endAdornment={
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {InputProps.endAdornment}
                </React.Fragment>
              }
              inputProps={{
                ...inputProps,
              }}
            />
          </CustomFormControl>
        );
      }}
      sx={{
        ".MuiAutocomplete-input": {
          padding: "0 !important",
          ...secondaryStyles,
        },
        ".MuiOutlinedInput-root": {
          padding: "0 0 0 20px",
          ...secondaryStyles,
        },
      }}
      disabled={disabled}
      disableClearable
    />
  );
}
interface ControlledAutoCompleteProps
  extends CustomFormControlRequiredPropsForFields {
  value: string | undefined;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  itemList: {
    label: string;
    value: string;
  }[];
  loading?: boolean;
  placeholder?: string;
  type?: InputFieldType;
}
