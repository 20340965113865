import CustomAutocompleteWithImageButtonList from "@/components/Form/CustomAutocompleteWithImageButtonList";
import CustomDatePicker from "@/components/Form/CustomDatePicker";
import CustomInput from "@/components/Form/InputFields/CustomInput";
import WatchedInputWrapper from "@/components/Form/WatchedInputWrapper";
import { useAdvertisersList } from "@/modules/Advertiser/advertiser.hook";
import { useListAdCreative } from "@/modules/Asset/Creative/asset.hook";
import { Channel, InputFieldType } from "@/types";
import MonetizationOnRoundedIcon from "@mui/icons-material/MonetizationOnRounded";
import { Grid } from "@mui/material";
import { Control, useWatch } from "react-hook-form";
import { getQuotaStat } from "../campaign.hook";
import { IFormProps } from "../campaign.interface";
import QuotaPopOver from "./quotaPopOver";
const INPUT_TYPE = "secondary";

export default function AdConfiguration({
  control,
  errors,
  isEdit,
  type,
  advertiserId,
  isMultiple = true,
  getValues,
  setValue,
}: AdConfigurationProps) {
  useAdvertisersList();
  const watchLineItemType = useWatch({
    control,
    name: "lineItemType",
  });

  const watchLineItemDateType = useWatch({
    control,
    name: "dateType",
  });
  const {
    data: ads,
    isLoading: adsLoading,
    isFetching: adsFetching,
  } = useListAdCreative({
    type,
    enabled: Boolean(advertiserId && watchLineItemType),
    queryParams: {
      advertiserId,
      adType: watchLineItemType || 0,
      isPagination: false,
    },
  });

  const {
    data: quota,
    isLoading: quotaLoading,
    isFetching: quotaFetching,
  } = getQuotaStat({
    enabled: Boolean(advertiserId),
    queryParams: {
      advertiserId,
    },
  });

  return (
    <>
      <Grid item xs={6}>
        <CustomInput
          name="name"
          label="Campaign Name"
          control={control}
          required={true}
          type="secondary"
          error={errors?.name?.message}
        />
      </Grid>
      <Grid item xs={6}>
        <CreativeInput
          control={control}
          errors={errors}
          data={ads}
          isMultiple={isMultiple}
          inputType={INPUT_TYPE}
          isLoading={adsLoading && adsFetching}
          disabled={isEdit}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomInput
          control={control}
          name="totalImpression"
          label={
            watchLineItemDateType === "DOUBLE"
              ? "Total Impressions"
              : "Monthly Impressions"
          }
          error={errors?.totalImpression?.message}
          type="secondary"
          inputType="number"
          popOverIcon={MonetizationOnRoundedIcon}
          popOver={<QuotaPopOver quota={quota} quotaLoading={quotaLoading} />}
          required={true}
        />
      </Grid>

      <Grid item xs={6}>
        <CustomInput
          name="numberOfAds"
          label="Number of Ads / User / Day"
          control={control}
          type="secondary"
          error={errors?.numberOfAds?.message}
          inputType="number"
          required={true}
        />
      </Grid>
      <Grid item xs={6}>
        <WatchedInputWrapper
          name={`startDate`}
          secondValueToWatch={`endDate`}
          control={control}
          valueToWatch=""
          secondValueFunction={(value: any) => ({
            datePickerProps: {
              maxDate: value,
              minDate: new Date(),
            },
          })}
        >
          <CustomDatePicker
            control={control}
            name="startDate"
            label="Start Date"
            required={true}
            error={errors?.startDate?.message}
            type="secondary"
          />
        </WatchedInputWrapper>
      </Grid>

      <Grid item xs={6}>
        <WatchedInputWrapper
          name={`endDate`}
          valueToWatch={`dateType`}
          isValueExpression={(watchValue) => watchValue === "DOUBLE"}
          secondValueToWatch={`startDate`}
          secondValueFunction={(value: any) => ({
            datePickerProps: {
              minDate: value,
              disabled: !value,
            },
          })}
          control={control}
          getValues={getValues}
          setValue={setValue}
          isRequired
        >
          <CustomDatePicker
            name="endDate"
            label="End Date"
            control={control}
            error={errors?.endDate?.message}
            type="secondary"
          />
        </WatchedInputWrapper>
      </Grid>
    </>
  );
}

function CreativeInput({
  control,
  errors,
  data,
  isMultiple,
  inputType,
  isLoading,
  disabled,
}: CreativeInputProps) {
  return (
    <CustomAutocompleteWithImageButtonList
      name="creatives"
      label="Select Ads"
      placeholder="ads"
      itemList={adsListMapping(data)}
      control={control}
      type={inputType}
      error={errors?.creatives?.message}
      isMultiple={isMultiple}
      loading={isLoading}
      disabled={disabled}
      required={true}
    />
  );
}

interface CreativeInputProps {
  data: any;
  isLoading: boolean;
  control: Control;
  inputType?: InputFieldType;
  errors: any;
  disabled: boolean;
  isMultiple?: boolean;
}

function adsListMapping(data: any) {
  return data?.rows?.map((item: any) => ({
    label: item.name,
    value: item.id,
    adId: item.adId,
    dimentions: item?.assetInfo?.dimentions,
    templateCreative: item?.CreativeTemplateDataValueMapping,
  }));
}

interface AdConfigurationProps extends IFormProps {
  channel: Channel;
  isEdit: boolean;
  activeStep?: any;
  switchActive?: any;
  setSwitchActive?: any;
  type?: any;
  advertiserId?: any;
  isMultiple?: any;
  dateTypeValue?: any;
}
