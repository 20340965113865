import { messages } from "@/messages";
import {
  CampaignType,
  EditMode,
  Field,
  FieldConfiguration,
  Fields,
  FieldsInputTypes,
} from "./template.interface";
import * as yup from "yup";
export function fieldsValidationSchema(
  fieldsByVariant: Record<string | number, Field>
) {
  const dynamicFieldsSchema = dynamicFieldsValidationSchema(fieldsByVariant);
  const validationSchema = yup.object({
    advertiserId: yup.number().nullable().required(messages.requiredField),
    creativeName: yup.string().max(255).required(messages.requiredField),
    ...dynamicFieldsSchema,
  });

  return validationSchema;
}

export function dynamicFieldsValidationSchema(
  fieldsByVariant: Record<string | number, Field>
) {
  const dynamicFieldsSchema: any = {};
  for (const variantFields in fieldsByVariant) {
    const fieldsToValidate = Object.values(fieldsByVariant[variantFields]);
    dynamicFieldsSchema[variantFields] = yup.object(
      getValidationSchema(fieldsToValidate)
    );
  }
  return dynamicFieldsSchema;
}

export function aiFormFieldValidationSchema(campaignType: CampaignType) {
  return yup.object({
    advertiserId: yup.number().required(messages.requiredField).nullable(),
    campaignType: yup.string().required(messages.requiredField),
    campaignObjective: yup.string().nullable().required(messages.requiredField),
    productServiceDescription: yup.string().required(messages.requiredField),
    valueProposition: yup.string(),
    toneStyle: yup.string().nullable(),
    keywords: yup.array().of(yup.string()),
    cta_text: yup.string().nullable(),
    ...(campaignType === "personalized"
      ? {
          audienceId: yup.string().nullable().required(messages.requiredField),
          personalizationVariables: yup.array().of(yup.string()),
        }
      : {}),
  });
}

function getValidationSchema(fields: FieldConfiguration[]) {
  const validationSchema: Record<string, any> = {};
  for (const field of fields) {
    validationSchema[field.name] = fieldValidation(
      field.inputType,
      field?.validations
    );
  }
  return validationSchema;
}

function fieldValidation(
  fieldType: FieldsInputTypes,
  validations?: Record<any, Record<string, any> | undefined>
) {
  const validation = validations?.[fieldType];
  if (fieldType === "color") {
    return yup.object().required(messages.requiredField);
  }

  if (fieldType === "text") {
    return yup
      .string()
      .max(validation?.maxLength ?? Infinity, messages.exceedsMaxLengthLimit)
      .min(validation?.minLength ?? 0)
      .required(messages.requiredField);
  }

  if (fieldType === "image") {
    return yup.mixed().required(messages.requiredField);
  }

  if (fieldType === "url") {
    return yup
      .string()
      .test(
        "url",
        messages.invalidUrl,
        (value: string | undefined) =>
          !value || new RegExp(/^https?:\/\//i).test(value)
      )
      .required(messages.requiredField);
  }

  return yup.string().required();
}
