import useImageProcessor from "@/hooks/useImageProcessor";
import { getFieldsValues, insertColor } from "../helper";
import { ComponentProps, MyStyles } from "../template.interface";

export default function TemplateImage(props: ComponentProps) {
  const { readImage } = useImageProcessor();
  const {
    id = "",
    styles = {},
    componentProps = {},
    content = {},
    wrapperProps = {},
    values = {},
    fields = { static: {}, dynamic: {} },
    isEdit,
  } = props;
  const { image } = getFieldsValues(content, values, fields, isEdit);

  let imageData = image ?? "";

  if (image && typeof image === "object" && "type" in image) {
    imageData = readImage(image) as string;
  }

  return (
    <div
      style={{
        ...(wrapperProps?.styles
          ? insertColor(wrapperProps.styles as MyStyles, values, fields, isEdit)
          : {}),
      }}
    >
      {imageData && (
        <img
          src={imageData}
          style={{ ...insertColor(styles as MyStyles, values, fields, isEdit) }}
          {...componentProps}
        />
      )}
    </div>
  );
}
