import { QueryFunctionContext } from "@tanstack/react-query";
import client from "../client";
import appendQueryParams from "@/utils/appendQueryParams";

const basePath = "/creative-templates";
export function getTemplateList(data: QueryFunctionContext) {
  return client.get(
    appendQueryParams(`${basePath}`, data?.meta?.queryParams ?? {})
  );
}

export function getTemplate(
  id: number | undefined,
  data: QueryFunctionContext
) {
  return client.get(
    appendQueryParams(`${basePath}/${id}`, data?.meta?.queryParams ?? {})
  );
}

export function getTemplateVariant(id: number, data: QueryFunctionContext) {
  return client.get(
    appendQueryParams(
      `${basePath}/variant/${id}`,
      data?.meta?.queryParams ?? {}
    )
  );
}

export const createManualCreative = <TDATA>(payload: TDATA) => {
  return client.post(`${basePath}/personalized-creative`, payload);
};

export const previewAiGeneratedCreative = <TDATA>(payload: TDATA) => {
  return client.post(`${basePath}/ai-generated-creative/preview`, payload);
};
export const createAiGeneratedImageDescriptions = <TDATA>(payload: TDATA) => {
  return client.post(`${basePath}/ai-generated-image-description`, payload);
};

export const createAiGeneratedCreative = <TDATA>(payload: TDATA) => {
  return client.post(`${basePath}/ai-generated-creative`, payload);
};

export function getCreativeTemplateFieldsByTemplateMappingId(
  templateMappingId: number,
  data: QueryFunctionContext
) {
  return client.get(
    appendQueryParams(
      `${basePath}/fields/mapping-id/${templateMappingId}`,
      data?.meta?.queryParams ?? {}
    )
  );
}
