import { USER_TYPE } from "@/constants";
import { useUserContext } from "@/context/UserContext";

export type UsePermission = ReturnType<typeof usePermission>;

export default function usePermission() {
  const { user } = useUserContext();
  const permission = user?.Role?.$permissions;
  const companyPermissions = user?.Role?.$companyPermissions;
  const isAdmin = user?.Role?.name === USER_TYPE.SUPER_ADMIN;
  const isAdvertiser =
    user?.Role?.name === USER_TYPE.ADVERTISER || Boolean(user.asAdvertiser);
  const isAgencyAdmin = user?.Role?.name === USER_TYPE.AGENCY_ADMIN;
  const isAgencyUser = user?.Role?.name === USER_TYPE.AGENCY_USER;
  const companyId = user.asAdvertiser ?? user.companyId;

  return {
    permission,
    companyPermissions,
    isAdmin,
    isAdvertiser,
    isAgencyAdmin,
    isAgencyUser,
    companyId,
    parentCompanyId: user.asAdvertiser ? user.companyId : user.parentCompanyId,
    isAsAdvertiser: Boolean(user.asAdvertiser),
  };
}
