import { getFieldsValues, insertColor } from "../helper";
import { ComponentProps, MyStyles } from "../template.interface";

export default function TemplateOverlayAnchorBox(props: ComponentProps) {
  const {
    id = "",
    children = null,
    styles = {},
    componentProps = {},
    values = {},
    fields = { static: {}, dynamic: {} },
    content = {},
    isEdit,
  } = props;
  const { url } = getFieldsValues(content, values, fields, isEdit);
  return (
    <div
      style={{ ...insertColor(styles as MyStyles, values, fields, isEdit) }}
      {...componentProps}
    >
      <a
        href={url}
        rel="noreferrer"
        target="_blank"
        style={{
          all: "initial",
          display: "block",
          width: "100%",
          height: "100%",
          position: "absolute",
          zIndex: 5,
          cursor: "pointer",
          background: "none",
        }}
      />
      {children}
    </div>
  );
}
