import { Box, Typography } from "@mui/material";
import TemplateBox from "./components/TemplateBox";
import TemplateCTA from "./components/TemplateCTA";
import TemplateImage from "./components/TemplateImage";
import TemplateList from "./components/TemplateList";
import TemplateOverlayAnchorBox from "./components/TemplateOverlayAnchorBox";
import TemplateTextBox from "./components/TemplateTextBox";
import {
  Component,
  ComponentMapping,
  Fields,
  Template,
} from "./template.interface";

const MAPPING: ComponentMapping = {
  overlayAnchorBox: TemplateOverlayAnchorBox,
  box: TemplateBox,
  textBox: TemplateTextBox,
  list: TemplateList,
  cta: TemplateCTA,
  image: TemplateImage,
};

function componentMapper(
  data: Component[],
  values: any,
  fields: Fields,
  isEdit: boolean
) {
  return data.map((component, index) => {
    if (component) {
      const { components = null, id, ...rest } = component;
      const MyComponent = MAPPING[id];
      if (!MyComponent) return null;
      if (components) {
        return (
          <MyComponent
            id={id}
            {...rest}
            key={`${index}${id}`}
            values={values}
            fields={fields}
            isEdit={isEdit}
          >
            {componentMapper(components, values, fields, isEdit)}
          </MyComponent>
        );
      }
      return (
        <MyComponent
          id={id}
          {...rest}
          key={`${index}${id}`}
          values={values}
          fields={fields}
          isEdit={isEdit}
        />
      );
    }
    return null;
  });
}

export default function DisplayTemplate({
  values,
  template,
  isEdit = false,
  isOverlay = true,
  width,
  height,
  isLabel = false,
}: {
  values: Record<string, any>;
  template: Template;
  isEdit?: boolean;
  isOverlay?: boolean;
  width: number;
  height: number;
  isLabel?: boolean;
}) {
  const { fields, definition } = template;
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      {isLabel && (
        <Box
          sx={{
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            width: "max-content",
            padding: "4px 12px",
            borderRadius: "4px",
          }}
        >
          <Typography variant="captionReg">
            {width} x {height} px
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          position: "relative",
          border: "1px solid rgba(204, 204, 204, 0.5)",
          boxShadow: "0 0 1px rgba(0, 0, 0, 0.1)",
          maxWidth: `calc(${width}px + 2px)`, //adjusting for the border
          maxHeight: `calc(${height}px + 2px)`, //adjusting for the border
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: width,
            height: height,
            backgroundColor: "rgba(0, 0, 0, 0)", // transparent overlay
            zIndex: isOverlay ? 10 : -2,
          }}
        />
        <div id="Test-Template">
          <div>{componentMapper([definition], values, fields, isEdit)}</div>
        </div>
      </Box>
    </Box>
  );
}
